import React, {useEffect, useState} from "react";
import axios from "axios";
import {BACKEND_URL} from "../const";
import {useNavigate} from "react-router-dom";

const FirstStep = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    const fetchUserAndQuestions = async () => {
      try {
        const userResponse = await axios.get(`${BACKEND_URL}/auth`);
        if (userResponse.data?.status !== "OK") {
          throw new Error("Invalid response from user auth");
        }

        console.log(userResponse.data?.data?.auth?.user);
        if (!userResponse.data?.data?.auth?.user?.first_step) {
          navigate('/home');
          return;
        }

        const questionsResponse = await axios.post(`${BACKEND_URL}/questions`);
        if (questionsResponse.data?.status === "OK") {
          setQuestions(questionsResponse.data?.data?.questions);
        } else {
          throw new Error("Failed to fetch questions");
        }
        setUser(userResponse.data?.data?.auth?.user);

      } catch (error) {
        localStorage.removeItem('token');
        navigate('/');
        console.error('Error:', error);
      }
    };

    fetchUserAndQuestions();
  }, []);

  function handleAnswerChange(questionId, answer) {
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [questionId]: answer
    }));
  }

  async function handleSubmitAnswers(event) {
    try {
      event.preventDefault();
      const response = await axios.post(`${BACKEND_URL}/answers`, {answers});
      if (response.status === 200) {
        navigate('/home');
      } else {
        console.error("Failed to save answers:", response.data);
      }
      console.log(answers);
    } catch (error) {
      console.error('Failed to save answers:', error);
    }
  }

  if (!user) {
    return <div className="flex justify-center items-center min-h-screen dark:text-white">Loading...</div>;
  } else {
    return (
        // <div className={"flex justify-center min-h-screen"}>
        //   <div
        //       className={"max-w-xl bg-cyan-100 w-full mx-auto flex flex-col justify-between rounded-xl h-screen"}>
        //     <div
        //         className={"fixed top-0 left-0 right-0 z-50 flex justify-center p-2"}>
        //       <div className="max-w-md w-full flex justify-around items-center">
        //         <form onSubmit={handleSubmitAnswers}>
        //           <div className="questions-container">
        //             {questions.map((question, index) => (
        //                 <div key={index} className="question">
        //                   <p>{question.question}</p>
        //                   {/* Itt jeleníthetők meg a válaszlehetőségek vagy egy input mező */}
        //                   <input type="text" required={true}
        //                          onChange={(e) => handleAnswerChange(question.id, e.target.value)}
        //                   />
        //                 </div>
        //             ))}
        //           </div>
        //           <button type="submit">Mentés</button>
        //         </form>
        //       </div>
        //     </div>
        //   </div>
        // </div>

        // <div className="min-h-screen flex flex-col items-center justify-center bg-cyan-100">
        //   <div className="w-full max-w-xl bg-white rounded-xl shadow-lg p-6">
        //     <h1 className="text-center text-2xl font-semibold text-gray-900">FirstStep</h1>
        //     <form onSubmit={handleSubmitAnswers} className="space-y-6 mt-4">
        //       {questions.map((question, index) => (
        //           <div key={index} className="flex flex-col">
        //             <label htmlFor={`question-${index}`}
        //                    className="mb-2 text-sm font-medium text-gray-700">{question.question}</label>
        //             <input
        //                 id={`question-${index}`}
        //                 type="text"
        //                 required
        //                 className=" -gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-500 focus:-cyan-500 block w-full p-2.5"
        //                 onChange={(e) => handleAnswerChange(question.id, e.target.value)}
        //             />
        //           </div>
        //       ))}
        //       <button type="submit"
        //               className="w-full py-2 px-4  -transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500">
        //         Mentés
        //       </button>
        //     </form>
        //   </div>
        // </div>
//todo meg ujra kell gondolni telon nagyon kicsi lesz,illetve home kinezet kellene ide is
        <div className="min-h-screen flex items-center justify-center p-4">
          <div className="w-full max-w-xl bg-cyan-100 rounded-xl shadow-lg p-6">
            <h1 className="text-xl font-bold text-center text-gray-800 mb-4">Első lépések...</h1>
            <p>Az oldal frissítésével új kérdések válnak elérhetővé.</p><br/>
            <form onSubmit={handleSubmitAnswers} className="space-y-4">
              {questions.map((question, index) => (
                  <div key={index} className="flex flex-col">
                    <label htmlFor={`question-${index}`}
                           className="text-gray-700 font-medium">{question.question}</label>
                    <input
                        id={`question-${index}`}
                        type="text"
                        required
                        className="mt-1 p-2 bg-cyan-50  rounded-lg w-full"
                        onChange={(e) => handleAnswerChange(question.id, e.target.value)}
                    />
                  </div>
              ))}
              <div className="flex justify-center mt-6">
                <button type="submit"
                        className="px-4 py-2 bg-cyan-600 text-white rounded-lg hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2">
                  Mentés
                </button>
              </div>
            </form>
          </div>
        </div>
    );
  }
};

export default FirstStep;