import React, {useEffect, useState} from "react";
import {BACKEND_URL} from "../const";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const Settings = ({user}) => {
  const [darkMode, setDarkMode] = useState(localStorage.getItem('theme') === 'dark');
  const navigate = useNavigate();

  const handleLogout = async () => {

    try { //todo ha kell bele olyan funkció, hogy mindenhonnan jelentkezzen ki
      // const token = localStorage.getItem('token');
      // if (token) {
      const response = await axios.delete(`${BACKEND_URL}/auth`);

      if (response.data?.status !== "OK") {
        throw new Error("Invalid response");
      }
      // else {
      //   localStorage.removeItem('token');
      //   navigate('/');
      // }
      // } else {
      //   throw new Error("Token is missing");
      // }
      console.log("Kijelentkezés");
    } catch (error) {
      console.error('Failed to logout:', error);
    } finally {
      localStorage.removeItem('token');
      navigate('/');
    }
  };

  const handleUserEdit = () => {
    // navigáció a felhasználói adatok szerkesztési oldalára vagy megnyitás egy modális ablakban
    console.log("Felhasználói adatok szerkesztése");

  };

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem('theme', newDarkMode ? 'dark' : 'light');
    document.body.classList.toggle('dark', newDarkMode);
  };

  // const toggleDarkMode = () => {
  //   // sötét mód kapcsolása
  //   setDarkMode(!darkMode);
  //   console.log("Sötét mód:", !darkMode ? "Bekapcsolva" : "Kikapcsolva");
  // };
  //
  // useEffect(() => {
  //   document.body.classList.toggle('dark', darkMode);
  //   localStorage.setItem('theme', darkMode ? 'dark' : 'light');
  // }, [darkMode]);

  // return (
  //     <div
  //         className="p-6 pt-20 min-h-screen text-center">
  //       <p className={"text-black"}>{user.name} - {user.email}</p>
  //
  //       <div className="mb-6">
  //         <button
  //             onClick={handleUserEdit}
  //             className="w-full p-2 bg-teal-800 hover:bg-blue-500 rounded-md"
  //         >
  //           <p className="flex items-center">
  //             Felhasználói adatok módosítása
  //           </p>
  //         </button>
  //       </div>
  //
  //       <div className="mb-6">
  //         <button
  //             onClick={toggleDarkMode}
  //             className="w-full p-2 bg-teal-800 hover:bg-blue-500 rounded-md"
  //         >
  //           <p className="flex items-center">
  //             Sötét téma {darkMode ? 'kikapcsolása' : 'bekapcsolása'}
  //           </p>
  //         </button>
  //       </div>
  //
  //       <div className="mb-6">
  //         <button
  //             onClick={toggleDarkMode}
  //             className="p-3.5 bubble w-full bubble shadow-lg rounded-2xl "
  //         >
  //           <p className="flex items-center ">
  //             Sötét téma {darkMode ? 'kikapcsolása' : 'bekapcsolása'}
  //           </p>
  //         </button>
  //       </div>
  //
  //       {/* További menüpontok helye */}
  //
  //       <div className="mt-2">
  //         <button
  //             onClick={handleLogout}
  //             className="w-full p-2 bg-red-700 rounded-md hover:bg-red-500"
  //         >
  //           <p className="flex items-center">
  //             Kijelentkezés
  //           </p>
  //         </button>
  //       </div>
  //     </div>
  // );

  return (
      <div className="pt-20 min-h-screen text-center">
        <p className={"dark:text-white"}>{user.name} - {user.email}</p>

        <button onClick={toggleDarkMode} className="w-full p-2 mb-6 bubble shadow-lg rounded-2xl">
          Sötét téma {darkMode ? 'kikapcsolása' : 'bekapcsolása'}
        </button>
        <button className="w-full p-2 mb-6 bubble shadow-lg rounded-2xl">
          Felhasználói adatok módosítása
        </button>
        <button className="w-full p-2 mb-6 bubble shadow-lg rounded-2xl">
          Új kérdések megválaszolása
        </button>
        <button className="w-full p-2 mb-6 bubble shadow-lg rounded-2xl">
          Válaszok módosítása
        </button>
        <button className="w-full p-2 mb-6 bubble shadow-lg rounded-2xl">
          Felhasználó törlése
        </button>
        <button onClick={handleLogout} className="w-full p-2 mb-6 bubble shadow-lg rounded-2xl">
          Kijelentkezés
        </button>
      </div>
  );
}

export default Settings;
