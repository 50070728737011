import React, {useEffect, useState} from "react";
import axios from "axios";
import {BACKEND_URL} from "../const";

const Profile = ({likeableUser}) => {
  //todo ezt majd at kell nevezni
  const [answers, setAnswers] = useState([]);
  console.log(likeableUser)

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const response = await axios.put(`${BACKEND_URL}/answers`, {userId: likeableUser.id});
        if (response.data?.status === "OK") {
          setAnswers(response.data?.data?.answered);
          console.log(response.data?.data)
        } else {
          throw new Error("Failed to fetch answers");
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchAnswers();
  }, [likeableUser.id]);
// // todo meg kell meg csinalni
//   if (!likeableUser) {
//     return (
//         <div className="p-4">
//           <p>No answers recorded.</p>
//         </div>
//     );
//   } else {
  return (
      <div className="">
        <p className={"text-center font-bold dark:text-white"}>{likeableUser?.name}</p>
        {(
            answers.map((answer, index) => (
                <div key={index} className="p-3.5 mb-4 bubble shadow-lg rounded-2xl text-justify">
                  <p>{answer.question}</p>
                  <p className="font-bold">{answer.answer}</p>
                </div>
            ))
        )}
      </div>
  );
  // }
};

export default Profile;

// import React, {useEffect, useState} from "react";
// import axios from "axios";
// import {BACKEND_URL} from "../const";
//
// const Profile = () => {
//
//   const [answers, setAnswers] = useState([]);
//
//   useEffect(() => {
//     const fetchUser = async () => {
//       try {
//         const response = await axios.get(`${BACKEND_URL}/answers`);
//         if (response.data?.status === "OK") {
//           console.log(response.data?.data?.answered);
//           setAnswers(response.data?.data?.answered);
//         } else {
//           throw new Error("Invalid response");
//         }
//       } catch (error) {
//         console.error('Failed to fetch user:', error);
//       }
//     }
//
//     fetchUser();
//   }, []);
//
//   if (answers === []) {
//     return <div>Loading...</div>;
//   } else {
//     return (
//         <div>
//           {/*todo felhasznalo adatai*/}
//           {answers.map((answer, index) => (
//               <div key={index} className="flex flex-col">
//                 <p>{answer.question}</p>
//                 <p>{answer.answer}</p>
//               </div>))}
//         </div>
//     );
//   }
// }
//
// export default Profile;

// import React, {useEffect, useState} from "react";
// import axios from "axios";
// import {BACKEND_URL} from "../const";
//
// const Profile = ({user}) => {
//   const [answers, setAnswers] = useState([]);
//
//   useEffect(() => {
//     const fetchAnswers = async () => {
//       try {
//         const response = await axios.get(`${BACKEND_URL}/answers`);
//         if (response.data?.status === "OK") {
//           setAnswers(response.data?.data?.answered);
//         } else {
//           throw new Error("Invalid response");
//         }
//       } catch (error) {
//         console.error('Failed to fetch answers:', error);
//       }
//     };
//
//     if (user) {
//       fetchAnswers();
//     }
//   }, [user]);
//
//   if (!answers.length) {
//     return <div className="flex justify-center items-center min-h-screen">Loading...</div>;
//   } else {
//     return (
//         <div className="flex flex-col items-center justify-center min-h-screen p-4">
//           <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl">
//             <h1 className="text-2xl font-bold text-center mb-4">User Profile</h1>
//             <div className="mb-6">
//               <h2 className="text-xl font-semibold">{user?.name}</h2>
//               <p className="text-gray-600">{user?.email}</p>
//             </div>
//             <div className="space-y-4">
//               {answers.map((answer, index) => (
//                   <div key={index} className="p-4 bg-gray-100 rounded-lg">
//                     <p className="font-semibold">{answer.question}</p>
//                     <p>{answer.answer}</p>
//                   </div>
//               ))}
//             </div>
//           </div>
//         </div>
//     );
//   }
// }
//
// export default Profile;

// import React, {useEffect, useState} from "react";
// import axios from "axios";
// import {BACKEND_URL} from "../const";
//
// const Profile = ({user}) => {
//   const [answers, setAnswers] = useState([]);
//
//   useEffect(() => {
//     const fetchAnswers = async () => {
//       try {
//         const response = await axios.get(`${BACKEND_URL}/answers`);
//         if (response.data?.status === "OK") {
//           setAnswers(response.data?.data?.answered);
//         } else {
//           throw new Error("Failed to fetch answers");
//         }
//       } catch (error) {
//         console.error('Error:', error);
//       }
//     };
//
//     fetchAnswers();
//   }, []);
//
//   return (
//       <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
//         <div className="w-full max-w-4xl bg-white rounded-xl shadow-lg p-6">
//           {user && (
//               <div className="text-center mb-6">
//                 <h1 className="text-2xl font-bold">{user.name}</h1>
//                 <p className="text-gray-500">{user.email}</p>
//               </div>
//           )}
//           <h2 className="text-xl font-semibold mb-4">Your Answers</h2>
//           {answers.length > 0 ? (
//               <div className="space-y-4">
//                 {answers.map((answer, index) => (
//                     <div key={index} className="p-4 bg-gray-50 rounded-lg">
//                       <h3 className="font-medium">{answer.question}</h3>
//                       <p>{answer.answer}</p>
//                     </div>
//                 ))}
//               </div>
//           ) : (
//               <p>No answers recorded.</p>
//           )}
//         </div>
//       </div>
//   );
// };
//
// export default Profile;


// import React, {useEffect, useState} from "react";
// import axios from "axios";
// import {BACKEND_URL} from "../const";
//
// const Profile = ({user}) => {
//   const [answers, setAnswers] = useState([]);
//
//   useEffect(() => {
//     const fetchAnswers = async () => {
//       try {
//         const response = await axios.get(`${BACKEND_URL}/answers`);
//         if (response.data?.status === "OK") {
//           setAnswers(response.data?.data?.answered);
//         } else {
//           throw new Error("Failed to fetch answers");
//         }
//       } catch (error) {
//         console.error('Error:', error);
//       }
//     };
//
//     fetchAnswers();
//   }, []);
//
//   return (
//       <div className="flex flex-col items-center justify-center min-h-screen">
//         <div className="w-full max-w-4xl rounded-xl shadow-lg p-6">
//           {/*{user && (*/}
//           {/*    <div className="text-center mb-6">*/}
//           {/*      <h1 className="text-2xl font-bold">{user.name}</h1>*/}
//           {/*      <p className="text-gray-500">{user.email}</p>*/}
//           {/*    </div>*/}
//           {/*)}*/}
//           <h2 className="text-xl font-semibold mb-4">Your Answers</h2>
//           <div className="space-y-4">
//             {answers.length > 0 ? (
//                 answers.map((answer, index) => (
//                     <div key={index}
//                          className="p-4 bg-gradient-to-r from-purple-500 to-pink-500 rounded-lg animate-pulse">
//                       <h3 className="font-bold text-white">{answer.question}</h3>
//                       <p className="text-white">{answer.answer}</p>
//                     </div>
//                 ))
//             ) : (
//                 <p>No answers recorded.</p>
//             )}
//           </div>
//         </div>
//       </div>
//   );
// };
//
// export default Profile;

// import React, {useEffect, useState} from "react";
// import axios from "axios";
// import {BACKEND_URL} from "../const";
//
// const Profile = ({user}) => {
//   const [answers, setAnswers] = useState([]);
//
//   useEffect(() => {
//     const fetchAnswers = async () => {
//       try {
//         const response = await axios.get(`${BACKEND_URL}/answers`);
//         if (response.data?.status === "OK") {
//           setAnswers(response.data?.data?.answered);
//         } else {
//           throw new Error("Failed to fetch answers");
//         }
//       } catch (error) {
//         console.error('Error:', error);
//       }
//     };
//
//     fetchAnswers();
//   }, []);
//
//   return (
//       <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
//         <div className="w-full max-w-4xl bg-white rounded-xl shadow-lg p-6">
//           {user && (
//               <div className="text-center mb-6">
//                 <h1 className="text-2xl font-bold">{user.name}</h1>
//                 <p className="text-gray-500">{user.email}</p>
//               </div>
//           )}
//           <h2 className="text-xl font-semibold mb-4">Your Answers</h2>
//           <div className="space-y-4">
//             {answers.length > 0 ? (
//                 answers.map((answer, index) => (
//                     <div key={index} className="bubble">
//                       <h3 className="font-bold">{answer.question}</h3>
//                       <p>{answer.answer}</p>
//                     </div>
//                 ))
//             ) : (
//                 <p>No answers recorded.</p>
//             )}
//           </div>
//         </div>
//       </div>
//   );
// };
//
// export default Profile;